import React, { useEffect, useState } from "react";
// import { Card, Table, Pagination, Nav } from "@themesberg/react-bootstrap";
import { Card, Table } from "@themesberg/react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheck,
	faCog,
	faPlus,
	faSearch
} from "@fortawesome/free-solid-svg-icons";
import {
	Col,
	Row,
	Form,
	Button,
	ButtonGroup,
	InputGroup,
	Dropdown
} from "@themesberg/react-bootstrap";

import { useApi } from "../hooks/useApi";
import { BorrarModal } from "../pages/components/BorrarModal";
import { envio } from "../helper/service";
import { CategoriasRow } from "./detalles/CategoriasRow";

const NOMBRE_API = "categorias";

export const CategoriasTAble = ({ cambiosVista }) => {
	// const totalTransactions = transactions.length;
	const { data } = useApi(NOMBRE_API);

	const [idBorrar, setidBorrar] = useState(0);
	const [mostarBorrar, setmostarBorrar] = useState(false);
	const [registros, setregistros] = useState([]);

	const eliminarRegistros = () => {
		const idEliminacion = registros[idBorrar].cod_pro;
		const estado = envio(
			NOMBRE_API + "/0",
			{
				codigoEliminar: idEliminacion
			},
			"DELETE"
		);
		data.splice(idBorrar, 1);
		setmostarBorrar(!estado);
	};

	useEffect(() => {
		setregistros(data);
	}, [data]);

	return (
		<>
			<div className="table-settings mb-4">
				<Row className="justify-content-between align-items-center">
					<Col xs={8} md={6} lg={3} xl={4}>
						<div className="btn-toolbar mb-2 mb-md-0">
							<Dropdown
								className="text-light btn btn-primary"
								onClick={() => cambiosVista(0)}
							>
								<FontAwesomeIcon icon={faPlus} className="me-2" />
								Nuevo Registro
							</Dropdown>
						</div>
					</Col>
					<Col xs={8} md={6} lg={3} xl={4}>
						<InputGroup>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control type="text" placeholder="Search" />
						</InputGroup>
					</Col>
					<Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
						<Dropdown as={ButtonGroup}>
							<Dropdown.Toggle
								split
								as={Button}
								variant="link"
								className="text-dark m-0 p-0"
							>
								<span className="icon icon-sm icon-gray">
									<FontAwesomeIcon icon={faCog} />
								</span>
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
								<Dropdown.Item className="fw-bold text-dark">
									Show
								</Dropdown.Item>
								<Dropdown.Item className="d-flex fw-bold">
									10
									<span className="icon icon-small ms-auto">
										<FontAwesomeIcon icon={faCheck} />
									</span>
								</Dropdown.Item>
								<Dropdown.Item className="fw-bold">20</Dropdown.Item>
								<Dropdown.Item className="fw-bold">30</Dropdown.Item>
								<Dropdown.Item className="fw-bold">50</Dropdown.Item>
								<Dropdown.Item className="fw-bold">100</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			</div>
			<Card border="light" className="table-wrapper table-responsive shadow-sm">
				<Card.Body className="pt-0">
					<Table hover className="user-table align-items-center">
						<thead>
							<tr>
								<th className="border-bottom">#</th>
								<th className="border-bottom">Nombre</th>
								<th className="border-bottom">Proceso</th>

								<th className="border-bottom">Actualización</th>
								<th className="border-bottom">Acciones</th>
							</tr>
						</thead>
						<tbody>
							{registros.map((fila, index) => (
								<CategoriasRow
									key={index}
									{...fila}
									cambiosVista={cambiosVista}
									idEliminar={setidBorrar}
									activarModalBorrar={setmostarBorrar}
									indice={index}
								/>
							))}
						</tbody>
					</Table>
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						{/* <Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev>Previous</Pagination.Prev>
								<Pagination.Item active>1</Pagination.Item>
								<Pagination.Item>2</Pagination.Item>
								<Pagination.Item>3</Pagination.Item>
								<Pagination.Item>4</Pagination.Item>
								<Pagination.Item>5</Pagination.Item>
								<Pagination.Next>Next</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalTransactions}</b> out of <b>25</b> entries
						</small> */}
					</Card.Footer>
				</Card.Body>
			</Card>
			<BorrarModal
				showDefault={mostarBorrar}
				setmostarBorrar={setmostarBorrar}
				setidBorrar={setidBorrar}
				eliminarRegistros={eliminarRegistros}
			/>
		</>
	);
};
