import React from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";

export const BorrarModal = ({
	showDefault,
	setmostarBorrar,
	setidBorrar,
	eliminarRegistros
}) => {
	const cancelarHandle = () => {
		setidBorrar(0);
		setmostarBorrar(false);
	};
	const confirmarHandle = () => {
		eliminarRegistros();
	};
	return (
		<Modal centered show={showDefault}>
			<Modal.Body>
				<h2>Esta seguro de eliminar el registro?</h2>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={cancelarHandle}>
					Cancelar
				</Button>
				<Button
					variant="secondary"
					className="ms-auto"
					onClick={confirmarHandle}
				>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
