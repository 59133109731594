import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Card } from "@themesberg/react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faStepBackward } from "@fortawesome/free-solid-svg-icons";

import { useApi } from "../hooks/useApi";
import { envio } from "../helper/service";
import { ConfirmacionModal } from "../pages/components/ConfirmacionModal";

const NOMBRE_API = "procesos";

export const ProcesosForm = ({ cambiosVista, idActivo }) => {
	const [datos, setdatos] = useState({
		codigo: 0
	});

	const { data } = useApi(NOMBRE_API + "/" + idActivo);

	const feteditar = async () => {
		const { procesos } = data;
		const { data: alias } = procesos;
		console.log(procesos);
		const { cod_pro, nom_pro } = alias;
		setdatos({
			codigo: cod_pro,
			nombre: nom_pro
		});
	};

	useEffect(() => {
		if (idActivo > 0) {
			if (data?.procesos) {
				feteditar();
			}
		}
	}, [data]);

	const { nombre } = datos;

	const HandleGuardar = () => {
		const estado = envio(NOMBRE_API, datos);
		setShowDefault(estado);
	};

	const [showDefault, setShowDefault] = useState(false);
	const handleClose = () => {
		cambiosVista();
		setShowDefault(false);
	};

	const handleInputChange = ({ target }) => {
		setdatos({
			...datos,
			[target.name]: target.value
		});
	};

	return (
		<>
			<Row>
				<Col>
					<Card border="light" className="bg-white shadow-sm mb-4">
						<Card.Body>
							<Form>
								<Row>
									<Col md={12} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Nombre</Form.Label>
											<Form.Control
												required
												type="text"
												name="nombre"
												placeholder="Nombre"
												onChange={handleInputChange}
												value={nombre}
											/>
										</Form.Group>
									</Col>
								</Row>

								<div className="mt-3">
									<Button variant="secondary" onClick={cambiosVista}>
										<FontAwesomeIcon icon={faStepBackward} className="me-2" />
										Regresar
									</Button>
									<Button
										className="mx-5"
										variant="primary"
										onClick={HandleGuardar}
									>
										<FontAwesomeIcon icon={faFileAlt} className="me-2" />
										Guardar
									</Button>
								</div>
								<ConfirmacionModal
									showDefault={showDefault}
									handleClose={handleClose}
								/>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};
