import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
	Col,
	Row,
	Form,
	Button,
	Container,
	InputGroup
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { LoginModal } from "../components/LoginModal";

export const Signin = ({ cerrarEntrar }) => {
	let history = useHistory();

	const [datos, setdatos] = useState({
		email: "",
		password: ""
	});

	const HandleIniciar = () => {
		if (email === "user@mottif.com" && password === "user") {
			cerrarEntrar(true);
			history.push("/administrador/casos");
		} else {
		}
	};
	useEffect(() => {
		history.length = 0;
		cerrarEntrar(false);
	}, []);

	const handleInputChange = ({ target }) => {
		// console.log(target);
		setdatos({
			...datos,
			[target.name]: target.value
		});
	};

	const { email, password } = datos;

	return (
		<main>
			<section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
				<Container>
					<Row className="justify-content-center form-bg-image">
						<Col
							xs={12}
							className="d-flex align-items-center justify-content-center"
						>
							<div className="bg-white shadow-login border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<h3 className="mb-0">Iniciar sesión</h3>
								</div>
								<Form className="mt-4">
									<Form.Group id="email" className="mb-4">
										<Form.Label>Email</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon={faEnvelope} />
											</InputGroup.Text>
											<Form.Control
												autoFocus
												required
												type="email"
												placeholder="example@email.com"
												onChange={handleInputChange}
												value={email}
												name="email"
											/>
										</InputGroup>
									</Form.Group>
									<Form.Group>
										<Form.Group id="password" className="mb-4">
											<Form.Label>Password</Form.Label>
											<InputGroup>
												<InputGroup.Text>
													<FontAwesomeIcon icon={faUnlockAlt} />
												</InputGroup.Text>
												<Form.Control
													required
													type="password"
													placeholder="Password"
													onChange={handleInputChange}
													value={password}
													name="password"
												/>
											</InputGroup>
										</Form.Group>
									</Form.Group>
									<Button
										variant="primary"
										type="Button"
										className="w-100"
										onClick={HandleIniciar}
									>
										Ingresar
									</Button>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
				{/* <LoginModal /> */}
			</section>
		</main>
	);
};
