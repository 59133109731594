import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Card } from "@themesberg/react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faStepBackward } from "@fortawesome/free-solid-svg-icons";

import { useApi } from "../hooks/useApi";
import { envio } from "../helper/service";
import { ConfirmacionModal } from "../pages/components/ConfirmacionModal";

export const UserForm = ({ cambiosVista, idActivo }) => {
	const [datos, setdatos] = useState({
		codigo: 0,
		nombre: "",
		correo: "",
		password: "",
		repassword: ""
	});

	const { data } = useApi("usuarios/" + idActivo);

	const feteditar = async () => {
		const { user } = data;
		console.log(user);
		const { cod_usu, nom_usu, mai_usu } = user;
		setdatos({
			codigo: cod_usu,
			nombre: nom_usu,
			correo: mai_usu,
			password: "",
			repassword: ""
		});
	};

	useEffect(() => {
		if (idActivo > 0) {
			if (data?.user) {
				feteditar();
			}
		}
	}, [data]);

	const { nombre, correo, password, repassword } = datos;

	const HandleGuardar = () => {
		const estado = envio("usuarios", datos);
		setShowDefault(estado);
	};

	const [showDefault, setShowDefault] = useState(false);
	const handleClose = () => {
		cambiosVista();
		setShowDefault(false);
	};

	const handleInputChange = ({ target }) => {
		setdatos({
			...datos,
			[target.name]: target.value
		});
	};

	return (
		<>
			<Row>
				<Col>
					<Card border="light" className="bg-white shadow-sm mb-4">
						<Card.Body>
							<Form>
								<Row>
									<Col md={6} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Nombre</Form.Label>
											<Form.Control
												required
												type="text"
												name="nombre"
												placeholder="Nombre"
												onChange={handleInputChange}
												value={nombre}
											/>
										</Form.Group>
									</Col>
									<Col md={6} className="mb-3">
										<Form.Group id="emal">
											<Form.Label>Email</Form.Label>
											<Form.Control
												required
												type="email"
												placeholder="email@company.com"
												name="correo"
												onChange={handleInputChange}
												value={correo}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Contraseña</Form.Label>
											<Form.Control
												required
												type="text"
												placeholder="Contraseña"
												name="password"
												onChange={handleInputChange}
												value={password}
											/>
										</Form.Group>
									</Col>
									<Col md={6} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Repetir Contraseña</Form.Label>
											<Form.Control
												required
												type="text"
												placeholder="Repetir Contraseña"
												name="repassword"
												onChange={handleInputChange}
												value={repassword}
											/>
										</Form.Group>
									</Col>
								</Row>

								<div className="mt-3">
									<Button variant="secondary" onClick={cambiosVista}>
										<FontAwesomeIcon icon={faStepBackward} className="me-2" />
										Regresar
									</Button>
									<Button
										className="mx-5"
										variant="primary"
										onClick={HandleGuardar}
									>
										<FontAwesomeIcon icon={faFileAlt} className="me-2" />
										Guardar
									</Button>
								</div>
								<ConfirmacionModal
									showDefault={showDefault}
									handleClose={handleClose}
								/>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};
