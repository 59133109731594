import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
// import { WebContext } from "../helper/WebContext";

// const urlLocal = "http://localhost:3000/api/json/";
const urlLocal = "http://cheeseapi.lys-software.com/";

export const useApi = ruta => {
	const [isLoading, setisLoading] = useState(true);
	const [data, setdata] = useState([]);
	const [error, seterror] = useState("");

	// const { dataWeb } = useContext(WebContext);
	// const { idioma } = dataWeb;
	let idi = "";

	// idioma ? (idi = "?idi=ing") : (idi = "");
	// const url = urlLocal + ruta + "/datos.json" + idi;
	const url = urlLocal + ruta + idi;
	// console.log(url);

	const doFecth = async () => {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(response.statusText);
			}
			const { data } = await response.json();
			// console.log(data);

			setdata(data);
		} catch (error) {
			seterror(error.message);
		}
		setisLoading(false);
	};

	useEffect(() => {
		doFecth();
	}, [url]);

	return {
		data,
		error,
		isLoading
	};
};

export default useApi;
