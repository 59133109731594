import React, { useEffect, useState } from "react";

import { UserForm } from "../../forms/UserForm";
import useApi from "../../hooks/useApi";
import { UserTable } from "../../tablas/UserTable";

export const Usuarios = () => {
	const [viewOpt, setviewOpt] = useState("table"); //estatus de la vista
	const [idActivo, setidActivo] = useState(0); //estatus de la vista
	const [borradoRefresh, setborradoRefresh] = useState(false); //estatus de la vista

	const chageForm = (id = 0) => {
		setidActivo(id);
		viewOpt === "table" ? setviewOpt("form") : setviewOpt("table");
	};

	const { data } = useApi("usuarios/" + idActivo);
	useEffect(() => {
		// console.log("id cambio", idActivo);
		if (idActivo > 0) {
			buscarDato(idActivo);
		}
	}, [idActivo]);

	const buscarDato = async id => {
		const { user } = data;
		console.log(user, "usuarios");
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<h4>Usuarios</h4>
				</div>
			</div>

			{viewOpt === "table" ? (
				<UserTable
					cambiosVista={chageForm}
					setborradoRefresh={setborradoRefresh}
					borradoRefresh={borradoRefresh}
				/>
			) : (
				<UserForm cambiosVista={chageForm} idActivo={idActivo} />
			)}
		</>
	);
};
