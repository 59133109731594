export const Routes = {
	// pages
	Presentation: { path: "/" },
	DashboardOverview: { path: "/dashboard/overview" },
	Transactions: { path: "/transactions" },
	Settings: { path: "/settings" },
	Upgrade: { path: "/upgrade" },
	BootstrapTables: { path: "/tables/bootstrap-tables" },
	Billing: { path: "/examples/billing" },
	Invoice: { path: "/examples/invoice" },
	// Signin: { path: "/examples/sign-in" },
	Signin: { path: "/sign-in" },
	Signup: { path: "/examples/sign-up" },
	ForgotPassword: { path: "/examples/forgot-password" },
	ResetPassword: { path: "/examples/reset-password" },
	Lock: { path: "/examples/lock" },
	NotFound: { path: "/sign-in" },
	ServerError: { path: "/examples/500" },
	Administrador: { path: "/administrador" },
	Asignaciones: { path: "/asignacion" },
	casos: { path: "/administrador/casos" },
	usuarios: { path: "/administrador/usuarios" },
	procesos: { path: "/administrador/proceosos" },
	categorias: { path: "/administrador/subcategorias" },

	// Asignaciones: { path: "/asignaciones" },
	casosasigandos: { path: "/asignaciones/casos" },
	informe: { path: "/asignaciones/informe" },

	// docs
	DocsOverview: { path: "/documentation/overview" },
	DocsDownload: { path: "/documentation/download" },
	DocsQuickStart: { path: "/documentation/quick-start" },
	DocsLicense: { path: "/documentation/license" },
	DocsFolderStructure: { path: "/documentation/folder-structure" },
	DocsBuild: { path: "/documentation/build-tools" },
	DocsChangelog: { path: "/documentation/changelog" },

	// components
	Accordions: { path: "/components/accordions" },
	Alerts: { path: "/components/alerts" },
	Badges: { path: "/components/badges" },
	Widgets: { path: "/widgets" },
	Breadcrumbs: { path: "/components/breadcrumbs" },
	Buttons: { path: "/components/buttons" },
	Forms: { path: "/components/forms" },
	Modals: { path: "/components/modals" },
	Navs: { path: "/components/navs" },
	Navbars: { path: "/components/navbars" },
	Pagination: { path: "/components/pagination" },
	Popovers: { path: "/components/popovers" },
	Progress: { path: "/components/progress" },
	Tables: { path: "/components/tables" },
	Tabs: { path: "/components/tabs" },
	Tooltips: { path: "/components/tooltips" },
	Toasts: { path: "/components/toasts" },
	WidgetsComponent: { path: "/components/widgets" }
};
