import React, { useEffect, useState } from "react";
import { ProcesosTable } from "../../tablas/ProcesosTable";
import { ProcesosForm } from "../../forms/ProcesosForm";

export const Procesos = () => {
	const [viewOpt, setviewOpt] = useState("table"); //estatus de la vista
	const [idActivo, setidActivo] = useState(0); //estatus de la vista
	const [borradoRefresh, setborradoRefresh] = useState(false); //estatus de la vista

	const chageForm = (id = 0) => {
		setidActivo(id);
		viewOpt === "table" ? setviewOpt("form") : setviewOpt("table");
	};
	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<h4>Procesos</h4>
				</div>
			</div>

			{viewOpt === "table" ? (
				<ProcesosTable
					cambiosVista={chageForm}
					setborradoRefresh={setborradoRefresh}
					borradoRefresh={borradoRefresh}
				/>
			) : (
				<ProcesosForm cambiosVista={chageForm} idActivo={idActivo} />
			)}
		</>
	);
};
