import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import { Signin } from "./examples/Signin";
import NotFoundPage from "./examples/NotFound";
// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import { Usuarios } from "./dashboard/Usuarios";
import { Procesos } from "./dashboard/Procesos";
import { Categorias } from "./dashboard/Categorias";
import { Casos } from "./dashboard/Casos";

const RouteWithLoader = ({ cerrarEntrar, component: Component, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 100);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Route
			{...rest}
			render={props => (
				<>
					<Preloader show={loaded ? false : true} />{" "}
					<Component cerrarEntrar={cerrarEntrar} {...props} />{" "}
				</>
			)}
		/>
	);
};

const RouteWithSidebar = ({
	cambioEstado,
	retornaEstado,
	component: Component,
	...rest
}) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 500);
		return () => clearTimeout(timer);
	}, []);

	const localStorageIsSettingsVisible = () => {
		return localStorage.getItem("settingsVisible") === "false" ? false : true;
	};

	const [showSettings, setShowSettings] = useState(
		localStorageIsSettingsVisible
	);

	const toggleSettings = () => {
		setShowSettings(!showSettings);
		localStorage.setItem("settingsVisible", !showSettings);
	};

	retornaEstado();

	if (!retornaEstado()) {
		return <Redirect cambioEstado={cambioEstado} to="/sign-in" />;
	}

	return (
		<Route
			{...rest}
			render={props => (
				<>
					<Preloader show={loaded ? false : true} />
					<Sidebar />

					<main className="content">
						<Navbar />
						<Component {...props} />
						<Footer
							toggleSettings={toggleSettings}
							showSettings={showSettings}
						/>
					</main>
				</>
			)}
		/>
	);
};

export const HomePage = () => {
	const [login, setlogin] = useState(false);

	const cambioEstado = arg => {
		// console.log("cambio el estado", arg);
		setlogin(arg);
	};

	const retornaEstado = () => {
		// console.log("consulta el estado", login);
		return login;
	};
	useEffect(() => {
		console.log("entro al padre");
	}, [login]);

	return (
		<Switch>
			{/* gestor*/}
			<RouteWithSidebar
				exact
				path={Routes.usuarios.path}
				component={Usuarios}
				retornaEstado={retornaEstado}
			/>
			<RouteWithSidebar
				exact
				path={Routes.procesos.path}
				component={Procesos}
				retornaEstado={retornaEstado}
			/>
			<RouteWithSidebar
				exact
				path={Routes.categorias.path}
				component={Categorias}
				retornaEstado={retornaEstado}
			/>
			<RouteWithSidebar
				exact
				path={Routes.casos.path}
				component={Casos}
				retornaEstado={retornaEstado}
			/>
			<RouteWithLoader
				exact
				path={Routes.Signin.path}
				component={Signin}
				cerrarEntrar={cambioEstado}
			/>
			<RouteWithLoader
				exact
				path={Routes.NotFound.path}
				component={NotFoundPage}
				cerrarEntrar={cambioEstado}
			/>

			<Redirect to={Routes.NotFound.path} />
		</Switch>
	);
};
