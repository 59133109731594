import React from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";

export const ConfirmacionModal = ({ showDefault, handleClose }) => {
	return (
		<Modal centered show={showDefault}>
			<Modal.Body>
				<p className="text-center">
					<h2>Registro exitoso</h2>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose} className="ms-auto">
					Continuar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
