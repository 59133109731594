import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
	faEdit,
	faEllipsisH,
	faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {
	Card,
	Button,
	Dropdown,
	ButtonGroup
} from "@themesberg/react-bootstrap";

export const CasosRow = ({
	cod_cas,
	nom_cas,
	asu_cas,
	updated_at,
	cambiosVista,
	idEliminar,
	activarModalBorrar,
	indice
}) => {
	const handleEditar = id => {
		cambiosVista(id);
	};

	const handleEliminar = id => {
		idEliminar(id);
		activarModalBorrar(true);
	};

	return (
		<tr>
			<td>
				<Card.Link className="fw-normal" onClick={() => handleEditar(cod_cas)}>
					{cod_cas}
				</Card.Link>
			</td>
			<td>
				<span className="fw-normal">{nom_cas}</span>
			</td>
			<td>
				<span className="fw-normal">{asu_cas}</span>
			</td>

			<td>
				<span className="fw-normal">{updated_at}</span>
			</td>

			<td>
				<Dropdown as={ButtonGroup}>
					<Dropdown.Toggle
						as={Button}
						split
						variant="link"
						className="text-dark m-0 p-0"
					>
						<span className="icon icon-sm">
							<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
						</span>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => handleEditar(cod_cas)}>
							<FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
						</Dropdown.Item>
						<Dropdown.Item
							className="text-danger"
							onClick={() => handleEliminar(indice)}
						>
							<FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Borrar
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	);
};
