import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Card } from "@themesberg/react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faStepBackward } from "@fortawesome/free-solid-svg-icons";

import { useApi } from "../hooks/useApi";
import { envio } from "../helper/service";
import { ConfirmacionModal } from "../pages/components/ConfirmacionModal";

const NOMBRE_API = "casos";

export const CasosForm = ({ cambiosVista, idActivo }) => {
	const [datos, setdatos] = useState({
		codigo: 0
	});

	const { data } = useApi(NOMBRE_API + "/" + idActivo);

	const { categorias, procesos, usuarios } = data;
	// const {data:listaCATE} =categorias;

	let listaCategorias = [];
	let listaProcesos = [];
	let listaUsuarios = [];

	if (categorias?.data) listaCategorias = categorias?.data;
	if (procesos?.data) listaProcesos = procesos?.data;
	if (usuarios?.data) listaUsuarios = usuarios?.data;

	const feteditar = async () => {
		//	console.log(data, "pura");
		const {
			cod_cas,
			nom_cas,
			mai_cas,
			asu_cas,
			des_cas,
			pro_cod_cas,
			sub_cod_cas,
			cod_usu_cas,
			sta_cas,
			procesos,
			categorias,
			usuarios,
			estados
		} = data;
		setdatos({
			codigo: cod_cas,
			nombre: nom_cas,
			correo: mai_cas,
			asunto: asu_cas,
			descripcion: des_cas,
			proceso: pro_cod_cas,
			categoria: sub_cod_cas,
			usuarioasignado: cod_usu_cas,
			astusactual: sta_cas,
			procesoslist: procesos,
			categoriaslist: categorias,
			usuarioslist: usuarios,
			statuslist: estados
		});
	};

	useEffect(() => {
		if (idActivo > 0) {
			if (data) {
				feteditar();
			}
		}
	}, [data]);

	const {
		codigo,
		nombre,
		correo,
		asunto,
		descripcion,
		proceso,
		categoria,
		usuarioasignado,
		astusactual
	} = datos;

	const HandleGuardar = () => {
		const estado = envio(NOMBRE_API, datos);
		setShowDefault(estado);
	};

	const [showDefault, setShowDefault] = useState(false);
	const handleClose = () => {
		cambiosVista();
		setShowDefault(false);
	};

	const handleInputChange = ({ target }) => {
		setdatos({
			...datos,
			[target.name]: target.value
		});
	};

	return (
		<>
			<Row>
				<Col>
					<Card border="light" className="bg-white shadow-sm mb-4">
						<Card.Body>
							<Form>
								<Row>
									<Col md={6} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Nombre</Form.Label>
											<Form.Control
												required
												type="text"
												name="nombre"
												placeholder="Nombre"
												onChange={handleInputChange}
												value={nombre}
											/>
										</Form.Group>
									</Col>

									<Col md={6} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Correo</Form.Label>
											<Form.Control
												required
												type="text"
												name="correo"
												placeholder="Correo"
												onChange={handleInputChange}
												value={correo}
											/>
										</Form.Group>
									</Col>

									<Col md={12} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Asunto</Form.Label>
											<Form.Control
												required
												type="text"
												name="asunto"
												placeholder="Asunto"
												onChange={handleInputChange}
												value={asunto}
											/>
										</Form.Group>
									</Col>

									<Col md={12} className="mb-3">
										<Form.Group id="firstName">
											<Form.Label>Descripcion</Form.Label>
											<Form.Control
												as="textarea"
												rows="10"
												name="descripcion"
												placeholder="Descripcion"
												onChange={handleInputChange}
												value={descripcion}
											/>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={6} className="mb-3">
										<Form.Group className="mb-3">
											<Form.Label>Proceso</Form.Label>
											<Form.Select
												name="proceso"
												placeholder="Proceso"
												onChange={handleInputChange}
												value={proceso}
											>
												{listaProcesos.map((item, index) => {
													// let sel = "";
													// console.log(datos.codproceso);
													if (item.nom_pro == datos.proceso)
														return (
															<option
																key={index}
																value={item.cod_pro}
																defaultValue
															>
																{item.nom_pro}
															</option>
														);
													else
														return (
															<option key={index} value={item.cod_pro}>
																{item.nom_pro}
															</option>
														);
												})}
											</Form.Select>
										</Form.Group>
									</Col>

									<Col md={6} className="mb-3">
										<Form.Group className="mb-3">
											<Form.Label>Categoria</Form.Label>
											<Form.Select
												name="categoria"
												placeholder="Categoria"
												onChange={handleInputChange}
												value={categoria}
											>
												{listaCategorias.map((item, index) => {
													// let sel = "";
													// console.log(datos.codproceso);
													if (item.nom_pro == datos.categoria)
														return (
															<option
																key={index}
																value={item.cod_pro}
																defaultValue
															>
																{item.nom_pro}
															</option>
														);
													else
														return (
															<option key={index} value={item.cod_pro}>
																{item.nom_pro}
															</option>
														);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<hr />

								<Row>
									<Col md={6} className="mb-3">
										<Form.Group className="mb-3">
											<Form.Label>Usuario Asignado</Form.Label>
											<Form.Select
												name="usuarioasignado"
												placeholder="Usuario"
												onChange={handleInputChange}
												value={usuarioasignado}
											>
												{listaUsuarios.map((item, index) => {
													if (item.nom_usu == datos.usuarioasignado)
														return (
															<option
																key={index}
																value={item.cod_usu}
																defaultValue
															>
																{item.nom_usu}
															</option>
														);
													else
														return (
															<option key={index} value={item.cod_usu}>
																{item.nom_usu}
															</option>
														);
												})}
											</Form.Select>
										</Form.Group>
									</Col>

									<Col md={6} className="mb-3">
										<Form.Group className="mb-3">
											<Form.Label>Status del caso</Form.Label>
											<Form.Select
												name="astusactual"
												placeholder="Status del caso"
												onChange={handleInputChange}
												value={astusactual}
											>
												<option value="Sin Asiganar">Sin Asiganar </option>
												<option value="En proceso">En proceso </option>
												<option value="Cerrado">Cerrado </option>
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>

								<div className="mt-3">
									<Button variant="secondary" onClick={cambiosVista}>
										<FontAwesomeIcon icon={faStepBackward} className="me-2" />
										Regresar
									</Button>
									<Button
										className="mx-5"
										variant="primary"
										onClick={HandleGuardar}
									>
										<FontAwesomeIcon icon={faFileAlt} className="me-2" />
										Guardar
									</Button>
								</div>
								<ConfirmacionModal
									showDefault={showDefault}
									handleClose={handleClose}
								/>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};
